// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"e1c6858cf350ab503702334ac4a2bebcdc9c211e"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import { ErrorEvent, EventHint } from "@sentry/nextjs";
import { IGNORED_ERRORS } from "./constants";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend: (event: ErrorEvent, hint: EventHint) => {
    const error = hint.originalException;

    // Check if the error message is in the ignored list
    if (error instanceof Error) {
      if (IGNORED_ERRORS.some((ignoredError) => error.message.toLowerCase().includes(ignoredError.toLowerCase()))) {
        return null;
      }
    }

    return event;
  },
});
